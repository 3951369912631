import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from "element-ui";
import VueAnimateNumber from "vue-animate-number";
import VueAwesomeSwiper from "vue-awesome-swiper";

import "element-ui/lib/theme-chalk/index.css";

Vue.use(Element);
Vue.use(VueAnimateNumber);
Vue.use(VueAwesomeSwiper);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
